<template>
  <el-container>
    <el-header style="height: 40px">
      <div class="logo"></div>
      <h1>鍵閉めたかなWeb2</h1>
    </el-header>
    <el-container>
      <el-main>
        <el-row>
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
            <div class="grid-content">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <i class="el-icon-question"></i>
                  <span>使い方</span>
                </div>
                <h4 class="helpTitle">鍵閉めたかなWeb２とは？</h4>
                <p>
                  気にしてしまう項目をLINEに送信記録することで心配を軽減するアプリです。
                </p>
                <p>
                  記録したら記録内容をLINEで自分（Keepメモ）や家族に送信できます。
                </p>
                <p class="red">
                  このアプリを利用するにはLINEアプリがインストールされたスマートフォンが必要です。
                </p>
                <el-collapse accordion>
                  <el-collapse-item title="鍵閉めたっけWeb２の使い方" name="1">
                    <ul class="help">
                      <li>
                        <h4><i class="el-icon-price-tag"></i>タグの設定</h4>
                        <ul>
                          <li>
                            よく確認する項目は簡単に記録することができます。
                          </li>
                          <li>
                            例えば、窓の鍵を記録したければ「窓の鍵」ボタンを押してみましょう。ボタンが緑色になれば設定完了です。
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>
                          <i class="el-icon-notebook-1"></i
                          >確認メモ（200文字まで）
                        </h4>
                        <ul>
                          <li>
                            タグにない項目を記録したい場合は確認メモに入力すると設定されます。
                          </li>
                          <li>
                            入力したら必ずキーボードの確定ボタンなどで確定してください。
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4><i class="el-icon-check"></i>LINEに送信</h4>
                        <ul>
                          <li>
                            確認したい項目を設定したら「LINEに送信」を押します。
                          </li>
                          <li>
                            送信先の選択画面がでたらKeepメモを選んで送信してください。
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </el-collapse-item>
                </el-collapse>
              </el-card>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
            <div class="grid-content">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <i class="el-icon-price-tag"></i>
                  <span>タグの設定</span>
                </div>
                <div class="tagButton">
                  <toggle-button
                    v-for="item in items"
                    :key="item.toggle"
                    :value="false"
                    :labels="{
                      checked: item.toggle + 'OK!!',
                      unchecked: item.toggle,
                    }"
                    :height="45"
                    :width="140"
                    ref="toggle"
                  >
                  </toggle-button>
                </div>
                <div></div>
              </el-card></div
          ></el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
            <div class="grid-content">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <i class="el-icon-notebook-1"></i>
                  <span>確認メモ（200文字まで）</span>
                </div>
                <div>
                  <el-form ref="form" :model="form">
                    <el-input
                      v-model="form.memo"
                      placeholder="メモを入力しよう"
                      maxlength="200"
                    ></el-input>
                  </el-form>
                </div>
              </el-card></div
          ></el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
            <div class="grid-content">
              <el-card class="box-card">
                <div>
                  <el-form ref="form" :model="form">
                    <el-form-item>
                      <el-button @click="submit">
                        <i class="el-icon-check"></i>
                        LINEに送信</el-button
                      >
                    </el-form-item>
                  </el-form>
                </div>
              </el-card>
            </div></el-col
          >
        </el-row>
      </el-main>
      <div class="Page-Btn" @click="scrollTop">TOP</div>
    </el-container>
  </el-container>
</template>
<style>
#app {
  text-align: left;
  background-color: #f7f7f7;
}
p {
  font-size: 13px;
}
.el-header {
  background-color: #67c23a;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 25px;
}
.el-header h1 {
  font-size: 20px;
  margin: 0;
  position: absolute;
  left: 32%;
}
@media only screen and (min-width: 750px) {
  .el-header h1 {
    left: 44%;
  }
}
.el-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  /* &:last-child {
      margin-bottom: 0;
    } */
}

.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.tagButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}
.vue-js-switch {
  margin: 10px;
}
.v-switch-label {
  font-size: 13px;
}
.el-button {
  width: 100%;
  margin-top: 10px;
  background-color: #75c791;
  border-radius: 30px;
  color: #ffffff;
  box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.6);
  font-weight: bold;
}
i {
  font-size: 23px;
  margin-right: 2px;
  color: orange;
  font-weight: bold;
}
.point {
  font-weight: bold;
}
.point_title {
  margin: 10px 0;
}
.help ul {
  list-style: none;
}
.Page-Btn {
  position: fixed;
  right: 14px;
  bottom: 14px;
  width: 64px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  border-radius: 50%;
  background: orange;
  opacity: 0.55;
  cursor: pointer;
}
.helpTitle {
  font-size: 16px;
  text-align: center;
}
h4 {
  margin: 10px;
}
ul {
  margin: 0;
  padding: 0 0 20px 0;
}
.el-collapse-item__header {
  font-size: 14px;
}
.el-card__header {
  background-color: #ffffff;
  border-bottom: orange solid 2px;
  margin: 0 10px;
  padding: 15px 10px 5px 0;
  text-align: center;
  font-weight: bold;
}
.red {
  color: red;
}
@media only screen and (min-width: 750px) {
  .red {
    font-size: 22px;
  }
}
.logo {
  border: 4px solid #ffffff;
  width: 20px;
  height: 13px;
  margin: 10px;
  border-radius: 5px;
  left: 20%;
  top: 15px;
  position: absolute;
}
.logo:before {
  border: 4px solid #ffffff;
  content: "";
  display: block;
  position: absolute;
  width: 3px;
  height: 3px;
  top: -11px;
  left: 5px;
  border-radius: 3px;
}
.logo:after {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 8px;
  top: 2px;
  left: 8px;
  background-color: orange;
  border-radius: 2px;
}
@media only screen and (min-width: 750px) {
  .logo {
    left: 40%;
  }
}
</style>
<script>
export default {
  data () {
    return {
      point: '',
      myDataVariable: '',
      form: {
        memo: ''
      },
      pointText: '',
      toggleText: [],
      toggleTextSend: [],
      togglebutton: false,
      items: [
        { toggle: '窓の鍵' },
        { toggle: '洗濯' },
        { toggle: '火の元' },
        { toggle: '水回り' },
        { toggle: 'エアコン' },
        { toggle: 'ストーブ' },
        { toggle: '照明' },
        { toggle: 'ゴミ捨て' },
        { toggle: '玄関の鍵' }
      ]
    }
  },
  methods: {
    scrollTop: function () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    submit () {
      for (let index = 0; index < this.items.length; index++) {
        if (this.$refs.toggle[index].toggled === true) {
          this.toggleText.push('%0A' + encodeURI('【' + this.$refs.toggle[index].labelUnchecked + '】確認OK!!'))
        }
      }
      this.toggleTextSend = this.toggleText.filter(function (x, i, self) {
        return self.indexOf(x) === i
      })
      location.href = 'https://line.me/R/share?text=' +
      encodeURI('■タグ') +
      this.toggleTextSend +
      '%0A' +
      encodeURI('■確認メモ') +
      '%0A' +
      encodeURI(this.form.memo)
    }
  }
}
</script>
